import React from 'react'
import Navbar from '../components/navbar'
import Sectionone from '../components/producttwo/secone'
import Sectiontwo from '../components/producttwo/sectwo'
import Sectionthree from '../components/producttwo/secthree'
import Footer from '../components/footer'

export default function serviceone() {
  return (
    <div>
        <Navbar/>
        <Sectionone/>
        <Sectiontwo/>
        <Sectionthree/>
        <Footer/>
    </div>
  )
}